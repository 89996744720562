import React from 'react'

function FormCardContainer({title, child, col}: any) {
  return (
    <div className={`${col} py-6 px-0 px-lg-2`}>
      <div className='border rounded-3 bg-white'>
        <div className='d-flex bg-light-primary p-4 rounded-top align-items-center'>
          <div className='pe-5 col-form-label fw-bold fs-5 w-100 text-center'>{title}</div>
        </div>
        {child}
      </div>
    </div>
  )
}

export default FormCardContainer
