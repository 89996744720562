/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {setLanguage, useLang} from '../../../i18n/Metronici18n'
import clsx from 'clsx'

const HeaderLanguage: FC = () => {
  const languages = [
    {
      lang: 'tr',
      name: 'Türkçe',
      shortName: 'TR',
    },
    {
      lang: 'en',
      name: 'English',
      shortName: 'EN',
    },
  ]

  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-150px'
      data-kt-menu='true'
    >
      <div className='w-100 py-1'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
              if (l.lang === 'ar') {
                localStorage.setItem('dir', 'rtl')
              } else {
                localStorage.setItem('dir', 'ltr')
              }
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              {l.name} - {l.shortName}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {HeaderLanguage}
