import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {ErrorMessage, Form, Formik} from 'formik'
import * as Yup from 'yup'
import InputComponent from '../../_metronic/layout/components/InputComponent'
import SubmitButton from '../../_metronic/layout/components/SubmitButton'
import {toast} from 'react-toastify'

function ChangePassword() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')

  const initialValues = {
    email: email,
    current_password: '',
    password: '',
    password_confirmation: '',
  }
  const changePasswordSchema = Yup.object().shape({
    current_password: Yup.string().trim().required(t('required_field_alert')),
    password: Yup.string()
      .required(t('required_field_alert'))
      .min(8, t('password_min_alert'))
      .matches(/[a-z]/, t('password_lower_alert'))
      .matches(/[A-Z]/, t('password_upper_alert'))
      .matches(/\d/, t('password_number_alert'))
      .matches(/[@$!%*?&]/, t('password_special_alert')),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('password_mismatch') // Şifrelerin eşleşmediği durumda gösterilecek hata mesajı
    ),
  })
  // https://bayi.wisnet.com.tr/api/netway-auth/change-password
  return (
    <>
      <PageTitle>{t('change_password')}</PageTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validate={(values) => {
          const errors: any = {}
          return changePasswordSchema
            .validate(values, {abortEarly: false})
            .then(() => {})
            .catch((err) => {
              // Tüm hataları al
              err.inner.forEach((e: any) => {
                if (!(e.path in errors)) errors[e.path] = []
                errors[e.path] = errors[e.path].concat(e.errors)
              })
              // Sadece 'password' alanı için doğrulama yap
              return changePasswordSchema
                .validateAt('password', values, {abortEarly: true})
                .then(() => {
                  // Doğrulama hatası yoksa, sadece 'password' alanındaki hataları döndür
                  return errors.password ? {password: errors.password} : errors // Tüm hataları döndür
                })
                .catch(() => errors) // Hata yakalanırsa, tüm hataları döndür
            })
        }}
        validationSchema={changePasswordSchema}
        onSubmit={async (values) => {}}
      >
        {({errors, touched, values, handleChange}) => (
          <div className='mb-5 mb-xxl-10 card col-12 col-md-6 mx-auto'>
            <Form className='row card-body g-4'>
              <div className='col-12'>
                <InputComponent
                  label={t('current_password')}
                  col='col-12'
                  controlId='current_password'
                  type='password'
                  name='current_password'
                />
              </div>
              <div className='col-12'>
                <div className='col-12'>
                  <InputComponent
                    label={t('new_password')}
                    col='col-12'
                    controlId='new_password'
                    type='password'
                    name='password'
                    errorMessage={false}
                  />
                  <ErrorMessage
                    name='password'
                    className='alert-text font-weight-bold '
                    render={(msg) => (
                      <div className='mt-2 alert alert-danger col-12'>
                        <ul className='mb-0'>
                          {errors.password &&
                            (errors.password as any).map((item: any, index: number) => (
                              <li key={index}> {item}</li>
                            ))}
                        </ul>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className='col-12'>
                <InputComponent
                  label={t('confirm_password')}
                  col='col-12'
                  controlId='confirm_password'
                  type='password'
                  name='password_confirmation'
                />
              </div>
              {/* Submit form */}
              <SubmitButton
                text={t('save')}
                width={'w-200px'}
                successAlert={t('change_password_success')}
              />
            </Form>
          </div>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword
