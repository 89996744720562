import React from 'react'
import {FloatingLabel, FormControl, FormSelect, Modal, Table} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import SubmitButton from '../../layout/components/SubmitButton'
import {TableMain} from '../widgets'
import {KTSVG} from '../../helpers'

function ReceiptDetailModal({show, setShow}: any) {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  const handleCloseModal = () => {
    setShow(false)
  }

  const columnItems = [
    {
      reading_information: t('daily_read'),
      first: '19.02.2024',
      last: '20.03.2024',
      difference: '30',
    },
    {
      reading_information: t('single_time_index'),
      first: '',
      last: '',
      difference: '160,881',
    },
    {
      reading_information: t('daytime_index'),
      first: '1.073,887',
      last: '1.139,407',
      difference: '65,520',
    },
    {
      reading_information: t('afternoon_index'),
      first: '864,044',
      last: '910,328',
      difference: '46,284',
    },
    {
      reading_information: t('night_index'),
      first: '861,654',
      last: '910,731',
      difference: '49,077',
    },
  ]

  const column: IColumnHeader[] = [
    {
      Header: t('reading_information'),
      accessor: 'reading_information',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: 'min-w-200px',
    },
    {
      Header: t('first'),
      accessor: 'first',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: 'min-w-150px',
    },
    {
      Header: t('last'),
      accessor: 'last',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: 'min-w-150px',
    },
    {
      Header: t('difference'),
      accessor: 'difference',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: '',
    },
  ]
  const columnItems2 = [
    {
      invoice_detail: t('energy_consumption_cost') + '-' + t('low_framing'),
      consumption: '160,881',
      unit_price: '1,341',
      price: '215,75',
    },
    {
      invoice_detail: t('taxes_and_funds'),
      consumption: '',
      unit_price: '',
      price: '25,84',
    },
    {
      invoice_detail: t('amount_to_be_paid'),
      consumption: '',
      unit_price: '',
      price: '241,60',
    },
  ]
  const column2: IColumnHeader[] = [
    {
      Header: t('invoice_detail'),
      accessor: 'invoice_detail',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: 'min-w-200px',
    },
    {
      Header: t('consumption'),
      accessor: 'consumption',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: 'min-w-150px',
    },
    {
      Header: t('unit_price'),
      accessor: 'unit_price',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: 'min-w-150px',
    },
    {
      Header: t('price') + ' (₺)',
      accessor: 'price',
      sortType: 'alphanumeric',
      disableSortBy: true,

      classname: '',
    },
  ]

  return (
    <Modal
      style={{background: ' rgba(0,0,0,0.3)'}}
      className=''
      size='xl'
      show={show}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Fatura Detayı</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row g-3'>
          <div className='col-6'>
            <FloatingLabel controlId='floatingInput' label={t('subscriber_name')} className=''>
              <FormControl disabled style={{height: '55px'}} placeholder='' value='Tony Soprano' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel
              controlId='floatingInput'
              label={t('identification_number')}
              className=''
            >
              <FormControl disabled style={{height: '55px'}} placeholder='' value='25544623351' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel controlId='floatingInput' label={t('contract_number')} className=''>
              <FormControl disabled style={{height: '55px'}} placeholder='' value='1145563290' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel controlId='floatingInput' label={t('installation_number')} className=''>
              <FormControl disabled style={{height: '55px'}} placeholder='' value='337564195' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel controlId='floatingInput' label={t('customer_number')} className=''>
              <FormControl disabled style={{height: '55px'}} placeholder='' value='451223327' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel controlId='floatingInput' label={t('amount_to_be_paid')} className=''>
              <FormControl disabled style={{height: '55px'}} placeholder='' value='269 ₺' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel controlId='floatingInput' label={t('payment_due_date')} className=''>
              <FormControl disabled style={{height: '55px'}} placeholder='' value='18.05.2024' />
            </FloatingLabel>
          </div>
          <div className='col-6'>
            <FloatingLabel
              controlId='floatingInput'
              label={t('bill_average_consumption')}
              className='text-break overflow-hidden'
            >
              <FormControl disabled style={{height: '55px'}} placeholder='' value='5,363' />
            </FloatingLabel>
          </div>
        </div>
        <TableMain
          basicTable={true}
          column={column}
          columnItems={columnItems}
          className='mb-5 mb-xl-8 mx-0 px-0'
        />
        <TableMain
          basicTable={true}
          column={column2}
          columnItems={columnItems2}
          className='mb-5 mb-xl-8 mx-0 px-0'
        />
        {/* <div className='row g-3 mt-4'>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('reading_information')}</div>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('first')}</div>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('last')}</div>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('difference')}</div>
          <div className='separator mb-4'></div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-3'>{t('daily_read')}</div>
              <div className='col-3'>19.02.2024</div>
              <div className='col-3'>20.03.2024</div>
              <div className='col-3'>30</div>
            </div>
          </div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-9'>{t('single_time_index')}</div>
              <div className='col-3'>160,881</div>
            </div>
          </div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-3'>{t('daytime_index')}</div>
              <div className='col-3'>1.073,887</div>
              <div className='col-3'>1.139,407</div>
              <div className='col-3'>65,520</div>
            </div>
          </div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-3'>{t('afternoon_index')}</div>
              <div className='col-3'>864,044</div>
              <div className='col-3'>910,328</div>
              <div className='col-3'>46,284</div>
            </div>
          </div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-3'>{t('night_index')}</div>
              <div className='col-3'>861,654</div>
              <div className='col-3'>910,731</div>
              <div className='col-3'>49,077</div>
            </div>
          </div>
        </div>
        <div className='row g-3 mt-6'>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('invoice_detail')}</div>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('consumption')}</div>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('unit_price')}</div>
          <div className='col-3 fw-bolder fs-5 mt-auto'>{t('price')} (₺)</div>
          <div className='separator mb-4'></div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-3'>
                {t('energy_consumption_cost')}-{t('low_framing')}
              </div>
              <div className='col-3'>160,881</div>
              <div className='col-3'>1,341</div>
              <div className='col-3'>215,75</div>
            </div>
          </div>
          <div className='col-12 fw-bold fs-6'>
            <div className='row'>
              <div className='col-9'>{t('taxes_and_funds')}</div>
              <div className='col-3'>25,84</div>
            </div>
          </div>
          <div className='col-12 fw-bolder fs-5'>
            <div className='row'>
              <div className='col-9'>{t('amount_to_be_paid')}</div>
              <div className='col-3'>241,60</div>
            </div>
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  )
}

export default ReceiptDetailModal
