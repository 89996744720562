import React from 'react'
import {Spinner} from 'react-bootstrap'

function LoadingEffect() {
  return (
    <div className='d-flex h-100 justify-content-center align-items-center'>
      <Spinner variant='primary' animation='border' />
    </div>
  )
}

export default LoadingEffect
