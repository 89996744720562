import React, {useState} from 'react'
import {FloatingLabel, FormControl, FormSelect, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import SubmitButton from '../../layout/components/SubmitButton'
import {TableMain} from '../widgets'
import {KTSVG} from '../../helpers'
import ReceiptDetailModal from './ReceiptDetailModal'

function ReceiptModal({showModal, setShowModal}: any) {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [show, setShow] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const columnItems = [
    {
      date: '18.04.2024',
      status: false,
      price: '9230 ₺',
    },
    {
      date: '18.03.2024',
      status: true,
      price: '6755 ₺',
    },
    {
      date: '18.02.2024',
      status: true,
      price: '6320 ₺',
    },
    {
      date: '18.01.2024',
      status: true,
      price: '6669 ₺',
    },
    {
      date: '18.12.2023',
      status: true,
      price: '4596 ₺',
    },
    {
      date: '18.11.2023',
      status: true,
      price: '5301 ₺',
    },
    {
      date: '18.10.2023',
      status: true,
      price: '5633 ₺',
    },
    {
      date: '18.09.2023',
      status: true,
      price: '4996 ₺',
    },
    {
      date: '18.08.2023',
      status: true,
      price: '4220 ₺',
    },
    {
      date: '18.07.2023',
      status: true,
      price: '4455 ₺',
    },
    {
      date: '18.06.2023',
      status: true,
      price: '3963 ₺',
    },
    {
      date: '18.05.2023',
      status: true,
      price: '4098 ₺',
    },
  ]

  const column: IColumnHeader[] = [
    {
      Header: t('date'),
      accessor: 'date',
      classname: '',
      disableSortBy: true,
    },
    {
      Header: t('invoice_amount'),
      accessor: 'price',
      sortType: 'alphanumeric',
      classname: '',
    },
    {
      Header: t('status'),
      accessor: 'status',
      sortType: 'alphanumeric',
      classname: '',
      Cell: (s: any) => (
        <div className={`text-${s.value === true ? 'success' : 'danger'}`}>
          {s.value === true ? t('paid') : t('not_paid')}
        </div>
      ),
    },
  ]
  const handleClick = () => {
    setShow(true)
  }

  const button = (
    <>
      <div
        onClick={handleClick}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb'
      >
        <KTSVG path='/media/icons/bootstrap/buttons/list.svg' className='svg-icon-4' />
      </div>
    </>
  )
  return (
    <Modal
      style={{background: ' rgba(0,0,0,0.3)'}}
      className=''
      size='xl'
      show={showModal}
      onHide={handleCloseModal}
      centered
    >
      <ReceiptDetailModal show={show} setShow={setShow} />
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{t('subscriber_info')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row g-3'>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('subscriber_id')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='1AD231FGGH12'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('subscriber_name')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='Tony Soprano'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('subscriber_type')} className=''>
                <FormControl disabled style={{height: '55px'}} placeholder='' value='İş yeri' />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('tariff_type')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value={t('single_time')}
                />
              </FloatingLabel>
            </div>
            <div className='col-12 '>
              <FloatingLabel controlId='floatingInput' label={t('address')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='Kültür, ESKİ MARİNA VİSTA AVM, 4304. Sk. No:1, MERSİN'
                />
              </FloatingLabel>
            </div>
            {/* <div className='separator mt-6'></div> */}
            <TableMain
              column={column}
              columnItems={columnItems}
              button={button}
              className=' my-2'
            />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default ReceiptModal
