/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useSortBy, useTable, usePagination, useGlobalFilter} from 'react-table'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import LoadingEffect from '../../../layout/components/LoadingEffect'
import TableSearch from './TableSearch'
import SubscriberEditModal from '../../modals/SubscriberEditModal'

interface Props {
  className: string // Gönderilmesi zorunlu
  setColumnItems?: any //Column items'ı burdan güncellemek için
  column: any[]
  columnItems: any[]
  columnButtons?: IColumnButton[]
  header?: JSX.Element
  deleteButton?: any //Silme işlemi var mı?
  confirmButton?: any // Onaylama işlemi var mı?
  editButton?: any // Güncelleme işlemi var mı?
  links?: any // Başka bir linke göndermek için buton
  kickUser?: any // Kullanıcı atma işlemi var mı?
  changeStatusButton?: any // Durum değiştirme butonu var mı?
  couponEditButton?: any // Coupon güncelleme butonu var mı?
  hotspotUserRegister?: any
  isFetching?: boolean // İlk data çekerkenki durum
  isFetchingAgain?: boolean // Sonraki data çekimlerinde sürekli true false olup loading effect çalıştırmak için
  totalFooter?: any // Toplam değerleri Tablonun en altında göstermek için
  button?: any
  showModal?: any
  setShowModal?: any
  basicTable?: any
}

const TableMain: React.FC<Props> = ({
  className,
  column,
  columnItems,
  columnButtons,
  setColumnItems,
  header,
  deleteButton,
  confirmButton,
  editButton,
  links,
  kickUser,
  changeStatusButton,
  couponEditButton,
  hotspotUserRegister,
  isFetching,
  isFetchingAgain,
  totalFooter,
  button,
  showModal,
  setShowModal,
  basicTable,
}: Props) => {
  const columns = useMemo(() => column, [column])
  const data = useMemo(() => columnItems, [columnItems])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // initialState: {
      //   sortBy: [
      //     {
      //       id: columns[0].accessor,
      //       desc: true,
      //     },
      //   ],
      // },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {globalFilter, pageIndex, pageSize} = state
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  return (
    <>
      <div className={`card py-2 ${className} mx-auto`}>
        {header && (
          <div className='py-8 px-4 bg-white rounded d-flex flex-column flex-lg-row text-center'>
            {header}
          </div>
        )}
        {!basicTable && (
          <div className='card-header border-0 align-items-center justify-content-between px-4'>
            {/* begin::SET PAGE SIZE */}

            {/* Gelen data 10'dan büyükse kaç tane gözükeceğini seç */}
            <div className='col-6 col-md-3 col-lg-2'>
              <select
                className='btn-sm card-label form-select cursor-pointer fs-6 text-black fw-bold border border-secondary p-2 rounded'
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {t('show')} {pageSize}
                  </option>
                ))}
              </select>
            </div>
            {/* end::SET PAGE SIZE */}
            <div className='col-6 col-md-9 col-lg-10 d-flex justify-content-end'>
              <TableSearch filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        )}

        {/* begin::Body */}

        <div className='card-body py-3 px-4'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              {...getTableProps()}
              className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
            >
              {/* begin::Table head */}
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className='fw-bolder text-muted'>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={`min-w-50px ps-2 ${column.render('classname')}`}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className='bi bi-arrow-down px-2'></i>
                            ) : (
                              <i className='bi bi-arrow-up px-2'></i>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    ))}
                    {button ? <th className='fw-bolder text-muted'>{t('processes')}</th> : null}
                  </tr>
                ))}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody {...getTableBodyProps()}>
                {isFetching || isFetchingAgain ? (
                  <tr>
                    <td colSpan={12}>
                      <div className='d-flex justify-content-center py-6'>
                        <LoadingEffect />
                      </div>
                    </td>
                  </tr>
                ) : page.length > 0 ? (
                  page.map((row, id) => {
                    prepareRow(row)
                    const rowData: any = row.original // Her row'un aşağıda id'yi kullanmak için
                    return (
                      <React.Fragment key={id}>
                        <tr
                          className={`bg-hover-secondary ${
                            rowData.is_active === 0 || rowData.status === 0
                              ? 'bg-secondary bg-opacity-50'
                              : ''
                          }`}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => (
                            <td
                              className={`  fw-bolder ps-2 py-4`}
                              style={{fontSize: '12px'}}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                          {button && <td className='d-flex'> {button}</td>}
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <tr>
                    <td className='bg-secondary rounded pt-5' colSpan={12}>
                      <p className='d-flex justify-content-center fw-bolder'>{t('no_data')}</p>
                    </td>
                  </tr>
                )}
              </tbody>
              {totalFooter /* Table'ın aşağısında toplam değerleri için */ && (
                <tbody>
                  <tr className='fw-bolder fs-6 border'>{totalFooter}</tr>
                </tbody>
              )}

              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>

        {/* begin::Body */}
        {/* begin::Pagination buttons */}
        {!basicTable && (
          <div className='mx-auto pb-6'>
            <button
              className='btn btn-bg-light btn-active-color-primary btn-sm mx-4'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {t('previous')}
            </button>
            <span>
              {t('page')}{' '}
              <strong>
                {pageOptions.length > 0 ? pageIndex + 1 : 0} / {pageOptions.length}
              </strong>{' '}
            </span>
            <button
              className='btn btn-bg-light btn-active-color-primary btn-sm mx-4'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {t('next')}
            </button>
          </div>
        )}
        {/* end::Pagination buttons */}
      </div>
    </>
  )
}

export {TableMain}
