import {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import Settings from '../pages/Settings'
import Subscribers from '../pages/Subscribers'
import Counter from '../pages/Counter'
import CounterDetail from '../pages/CounterDetail'
import ChangePassword from '../pages/ChangePassword'
import Breakdowns from '../pages/Breakdowns'
import Monitoring from '../pages/Monitoring'
import BreakdownCreate from '../pages/BreakdownCreate'

const PrivateRoutes = () => {
  const SumPanel = lazy(() => import('../pages/SumPanel'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route
          path='/home'
          element={
            <SuspensedView>
              <SumPanel />
            </SuspensedView>
          }
        />
        <Route
          path='/settings'
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />
        <Route
          path='/subscribers'
          element={
            <SuspensedView>
              <Subscribers />
            </SuspensedView>
          }
        />
        <Route
          path='/counter'
          element={
            <SuspensedView>
              <Counter />
            </SuspensedView>
          }
        />
        <Route
          path='/counter-detail'
          element={
            <SuspensedView>
              <CounterDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/change-password'
          element={
            <SuspensedView>
              <ChangePassword />
            </SuspensedView>
          }
        />
        <Route
          path='/breakdowns'
          element={
            <SuspensedView>
              <Breakdowns />
            </SuspensedView>
          }
        />
        <Route
          path='/breakdowns/create'
          element={
            <SuspensedView>
              <BreakdownCreate />
            </SuspensedView>
          }
        />
        <Route
          path='/monitoring'
          element={
            <SuspensedView>
              <Monitoring />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
