import React from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {FloatingLabel, FormControl, ListGroup} from 'react-bootstrap'
import {useIntl} from 'react-intl'

function CounterDetail() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  return (
    <>
      <PageTitle>Sayaç detayı</PageTitle>
      <div className='row d-flex g-3'>
        <div className='col-lg-6'>
          <div className='card m-2 h-100'>
            <div className=' p-4 p-xl-6 row d-flex'>
              <div className='text-center fw-bolder fs-4 col-12'>
                {t('counter_info')} <div className='separator'></div>
              </div>
              <div className='col-12 mt-5'>
                <div className='row g-3'>
                  <div className='col-6'>
                    <FloatingLabel controlId='floatingInput' label={t('brand')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        defaultValue='M243'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-6'>
                    <FloatingLabel controlId='floatingInput' label={t('model')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        defaultValue='K221'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-6'>
                    <FloatingLabel controlId='floatingInput' label={t('seri_no')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        defaultValue='12AR55D2GPP2'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='card m-2 h-100'>
            <div className=' p-4 p-xl-6 row d-flex g-2'>
              <div className='text-center fw-bolder fs-4 col-12'>
                {t('location')} <div className='separator'></div>
              </div>
              <div className='col-12 mt-5'>
                <div className='row g-3'>
                  <div className='col-12'>
                    <FloatingLabel controlId='floatingInput' label={t('address')} className=''>
                      <FormControl
                        disabled
                        style={{height: '75px'}}
                        className='fs-7'
                        as='textarea'
                        defaultValue='Mersin Üniversitesi Çiftlikköy Kampüsü, Teknopark 2.Bina No: 35-B / B03, 33343 Yenişehir/Mersin/Turkiye'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-6'>
                    <FloatingLabel controlId='floatingInput' label={t('latitude')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        defaultValue='36.812103'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-6'>
                    <FloatingLabel controlId='floatingInput' label={t('longitude')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        defaultValue='34.641479'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-12'>
          <div className='card m-2 h-100'>
            <div className=' p-4 p-xl-6 row d-flex g-2'>
              <div className='text-center fw-bolder fs-4 col-12'>
                {t('last_invoice')} <div className='separator'></div>
              </div>
              <div className='col-12 mt-5'>
                <div className='row g-3'>
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T1' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-success text-white'
                        defaultValue='40 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>{' '}
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T2' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-success text-white'
                        defaultValue='50 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>{' '}
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T3' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-success text-white'
                        defaultValue='60 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label={t('total')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-success text-white'
                        defaultValue='150 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              <div className='text-center fw-bolder fs-4 col-12 mt-4'>
                {t('last_read')} <div className='separator'></div>
              </div>
              <div className='col-12 mt-5'>
                <div className='row g-3'>
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T1' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-danger text-white'
                        defaultValue='25 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>{' '}
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T2' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-danger text-white'
                        defaultValue='37 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>{' '}
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T3' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-danger text-white'
                        defaultValue='43 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label={t('total')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-danger text-white'
                        defaultValue='105 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              <div className='text-center fw-bolder fs-4 col-12 mt-12'>
                {t('expense')} <div className='separator'></div>
              </div>
              <div className='col-12 mt-5'>
                <div className='row g-3'>
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T1' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-white'
                        defaultValue='15 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>{' '}
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T2' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-white'
                        defaultValue='13 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>{' '}
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label='T3' className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-white'
                        defaultValue='17 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='col-3'>
                    <FloatingLabel controlId='floatingInput' label={t('total')} className=''>
                      <FormControl
                        disabled
                        style={{height: '55px'}}
                        className='bg-white'
                        defaultValue='45 kW'
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              <div className='col-12 d-flex justify-content-center mt-10'>
                <div className='bg-primary rounded py-2 px-4 text-white  fs-5 hoverable'>
                  {t('read_again')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CounterDetail
