import React, {useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import FormCardContainer from '../../_metronic/layout/components/FormCardContainer'
import {FloatingLabel, FormControl, FormSelect} from 'react-bootstrap'
import {Header} from '../../_metronic/layout/components/header/Header'
import SubmitButton from '../../_metronic/layout/components/SubmitButton'

function Settings() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [data, setData] = useState({
    r_multi1: 1.48,
    r_multi2: 1.68,
    r_multi3: 1.28,
    r_single: 1.48,
    w_multi1: 1.55,
    w_multi2: 1.75,
    w_multi3: 1.35,
    w_single: 1.55,
    i_multi1: 1.75,
    i_multi2: 1.95,
    i_multi3: 1.55,
    i_single: 1.75,
  })

  const handleChange = (e: any) => {
    const {name, value} = e.target
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  return (
    <>
      <PageTitle>{t('settings')}</PageTitle>
      <div className='row'>
        <FormCardContainer
          col='col-12 col-lg-6'
          title={t('residence')}
          child={
            <div>
              <div className='p-6 row'>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='fw-bold fs-5'>
                      {t('multi_price_settings')}
                      <div className='separator mb-4'></div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T1 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='r_multi1'
                        onChange={handleChange}
                        value={data.r_multi1}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T2 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='r_multi2'
                        onChange={handleChange}
                        value={data.r_multi2}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T3 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='r_multi3'
                        onChange={handleChange}
                        value={data.r_multi3}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className='col-md-6 mt-6 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='fw-bold fs-5'>
                      {t('single_price_settings')}
                      <div className='separator mb-4'></div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='r_single'
                        onChange={handleChange}
                        value={data.r_single}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <FormCardContainer
          col='col-12 col-lg-6'
          title={t('workplace')}
          child={
            <div>
              <div className='p-6 row'>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='fw-bold fs-5'>
                      {t('multi_price_settings')}
                      <div className='separator mb-4'></div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T1 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='w_multi1'
                        onChange={handleChange}
                        value={data.w_multi1}
                        type='number'
                        style={{height: '55px'}}
                        placeholder='123'
                      />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T2 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='w_multi2'
                        onChange={handleChange}
                        value={data.w_multi2}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T3 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='w_multi3'
                        onChange={handleChange}
                        value={data.w_multi3}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className='col-md-6 mt-6 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='fw-bold fs-5'>
                      {t('single_price_settings')}
                      <div className='separator mb-4'></div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='w_single'
                        onChange={handleChange}
                        value={data.w_single}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <FormCardContainer
          col='col-12 col-lg-6'
          title={t('industry')}
          child={
            <div>
              <div className='p-6 row'>
                <div className='col-md-6'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='fw-bold fs-5'>
                      {t('multi_price_settings')}
                      <div className='separator mb-4'></div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T1 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='i_multi1'
                        onChange={handleChange}
                        value={data.i_multi1}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T2 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='i_multi2'
                        onChange={handleChange}
                        value={data.i_multi2}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                  <div className='d-flex align-items-center gap-4 mt-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`T3 ${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='i_multi3'
                        onChange={handleChange}
                        value={data.i_multi3}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className='col-md-6 mt-6 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='fw-bold fs-5'>
                      {t('single_price_settings')}
                      <div className='separator mb-4'></div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-4 justify-content-center'>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={`${t('price')} (₺)`}
                      className=''
                    >
                      <FormControl
                        name='i_single'
                        onChange={handleChange}
                        value={data.i_single}
                        type='number'
                        style={{height: '55px'}}
                        placeholder=''
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        {/* Submit form */}
        <SubmitButton text={t('save')} width={'w-200px'} successAlert={t('settings_updated')} />
      </div>
    </>
  )
}

export default Settings
