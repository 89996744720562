// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {useIntl} from 'react-intl'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
)
function ImageHandler({col, picture, setPicture}: any) {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  return (
    <div className={`col-${col} mx-auto`}>
      {console.log(picture)}
      <FilePond
        allowFileTypeValidation={true}
        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
        labelFileTypeNotAllowed={t('wrong_file_format')}
        fileValidateTypeLabelExpectedTypes={t('allowed_file_format')}
        instantUpload={false}
        onupdatefiles={setPicture}
        files={picture}
        allowMultiple={false}
        server='/api'
        name='picture'
        allowProcess={false}
        credits={false}
        labelIdle={t('image_handler_label')}
      />
    </div>
  )
}

export default ImageHandler
