/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {Form, Formik} from 'formik'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import InputComponent from '../../../../_metronic/layout/components/InputComponent'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {HeaderLanguage} from '../../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'

const initialValues = {
  email: '',
  password: '',
}
const languages = [
  {
    lang: 'tr',
    name: 'Türkçe',
    flag: toAbsoluteUrl('/media/flags/turkey.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
]

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  // Reinit yapmazsak language select butonu çalışmıyor.
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('email_valid_alert'))
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(t('email_required')),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(t('password_required')),
  })

  const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const lang = useLang()
  console.log(navigator.language)
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={async (values) => {
        setLoading(true)
        try {
          setCurrentUser({
            data: [
              {
                locations: [
                  {
                    description: '',
                    id: 1,
                    nasname: '',
                    shortname: '',
                  },
                ],
                user: {
                  created_at: '',
                  email: '',
                  id: 0,
                  name: '',
                  updated_at: '',
                },
              },
            ],
            is_success: true,
          })
          saveAuth({is_true: true, access_token: '123'})
        } catch (error) {}
      }}
    >
      {({values, errors, touched}) => (
        <Form className='form w-100' noValidate id='kt_login_signin_form'>
          {/* begin::Heading */}
          <div className='text-center mb-4'>
            <h1>{t('netway_iot_panel')}</h1>
          </div>
          {/* begin::Language */}
          <div
            className={clsx(
              'd-flex align-items-center justify-content-end',
              toolbarButtonMarginClass
            )}
            style={{top: '1rem', right: '1rem'}}
            id='kt_header_user_menu_toggle'
          >
            {/* begin::Toggle */}
            <div
              className={clsx(
                'cursor-pointer symbol bg-secondary w-100px mb-4 bg-opacity-25 btn btn-icon btn-active-light-primary btn-custom',
                toolbarUserAvatarHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              {currentLanguage?.name}
            </div>
            <HeaderLanguage />
            {/* end::Toggle */}
          </div>
          {/* end::language */}
          {/* begin::Form group */}
          <div className='fv-row mb-4'>
            <InputComponent
              label={t('email')}
              col={`col-12`}
              controlId='floatingEmail'
              type='email'
              name='email'
              isAutoComplete='on'
            />
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-8 '>
            <InputComponent
              label={t('password')}
              col={`col-12`}
              controlId='floatingPassword'
              type='password'
              name='password'
              isAutoComplete='on'
            />
          </div>
          {/* end::Form group */}

          {/* begin::Action */}

          <div className=' pb-4 mt-10'>
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className={`btn btn-primary w-100 py-4`}
                disabled={loading}
              >
                {!loading && <span className='indicator-label'>{t('continue')}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {t('please_wait')}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className='d-flex'>
            <div className='d-flex w-100 mt-4'>
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
              >
                {t('forgot_password')}
              </Link>
              {/* end::Link */}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
