import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

function SubmitButton({width, text, successAlert}: any) {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  const [loading, setLoading] = useState(false)
  const handleClick = () => {
    setLoading(true)

    // Zaman aşımını 2 saniye olarak ayarladık, isteğe bağlı olarak süreyi değiştirebilirsiniz
    setTimeout(() => {
      setLoading(false)
      successAlert && toast.success(successAlert)
    }, 1000)
  }

  return (
    <div className='d-flex justify-content-center pb-4 mt-10'>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className={`btn btn-primary ${width} py-4`}
          disabled={loading}
          onClick={handleClick}
        >
          {!loading && <span className='indicator-label'>{text}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {t('please_wait')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default SubmitButton
