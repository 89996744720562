import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {TableMain} from '../../_metronic/partials/widgets'
import {KTSVG} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'

function Counter() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const columnItems = []

  for (let i = 0; i < 20; i++) {
    columnItems.push({
      counter_serial_no: 5882183412 + i,
      counter_brand: `Brand${i}`, // Sıralı markaları ekliyoruz, M82 olmayacak.
      counter_model: 'M82',
      subscriber_id: '88MSA129KK23',
    })
  }

  const column: IColumnHeader[] = [
    {
      Header: t('counter_serial_no'),
      accessor: 'counter_serial_no',
      sortType: 'alphanumeric',
      classname: 'min-w-200px',
    },
    {
      Header: t('counter_brand'),
      accessor: 'counter_brand',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
    {
      Header: t('counter_model'),
      accessor: 'counter_model',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
    {
      Header: t('subscriber_id'),
      accessor: 'subscriber_id',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
  ]

  const button = (
    <>
      <Link
        to='/counter-detail'
        className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1 mb'
      >
        <KTSVG path='/media/icons/bootstrap/buttons/lightning-fill.svg' className='svg-icon-4' />
      </Link>
    </>
  )

  return (
    <>
      <PageTitle>{t('counters')}</PageTitle>
      <TableMain
        column={column}
        columnItems={columnItems}
        button={button}
        className='mb-5 mb-xl-8'
      />
    </>
  )
}

export default Counter
