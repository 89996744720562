import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel} from './_models'
import * as authHelper from './AuthHelpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: IUserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<IUserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUserModel | undefined>()
  const saveAuth = async (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      try {
        const data = localStorage.getItem('kt-auth-react-v')
        if (data) {
          const dataJSON = JSON.parse(data)
        }
      } catch {}
    } else {
      // Auth olmadığında data'yı local storage'dan sil
      authHelper.removeAuth()
      localStorage.removeItem('data')
      localStorage.removeItem('selectedLocation')
    }
  }

  const defaultAccordionState = () => {
    const state = {
      locationPage: '0',
      locationEdit: '0',
      viewSettingsPage: '0',
      surveyPage: '0',
      campaignPage: '0',
      promotionPage: '0',
      defaultLimitationsPage: '0',
      hotspotPage: '0',
    }
    localStorage.setItem('accordionState', JSON.stringify(state))
  }

  // Logout olunca data'yı local storage'dan sil
  const logout = () => {
    saveAuth(undefined)
    localStorage.removeItem('data')
    localStorage.removeItem('selectedLocation')
    setCurrentUser(undefined)
    console.clear()
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (access_token: string) => {
      try {
        if (!didRequest.current) {
          const user = localStorage.getItem('kt-auth-react-v')
          if (user) {
            const userJSON = JSON.parse(user)
            const data = userJSON
            if (data) {
              setCurrentUser(data)
            }
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser(auth.access_token)
    } else {
      logout()
      setShowSplashScreen(false)
      console.log('else')
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
