/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

const HeaderUserMenu: FC = () => {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  const {logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <Link to='/change-password' className='menu-link px-5'>
          <span className='menu-title position-relative'>{t('change_password')}</span>
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {t('exit')}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
