import React from 'react'
import {FloatingLabel, FormControl, FormSelect, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import SubmitButton from '../../layout/components/SubmitButton'

function BreakdownModal({showModal, setShowModal}: any) {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handlePrint = () => {
    window.print()
  }
  return (
    <Modal
      style={{background: ' rgba(0,0,0,0.3)'}}
      size='lg'
      show={showModal}
      onHide={handleCloseModal}
      centered
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{t('breakdown_detail')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row g-3'>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('transformer_id')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='1AD231FGGH12'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('breakdown_id')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='90FF31FGAS412'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('breakdown_type')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='Arıza Tipi 1'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('location')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='Bakü / 34.2312, 34.2442'
                />
              </FloatingLabel>
            </div>
          </div>
          <div className='row g-3 pt-4'>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('technician_name')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='Tony Soprano'
                />
              </FloatingLabel>
            </div>{' '}
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('technician_phone')} className=''>
                <FormControl disabled style={{height: '55px'}} placeholder='' value='05335466621' />
              </FloatingLabel>
            </div>
            <div className='col-12'>
              <FloatingLabel controlId='floatingTextarea2' label={t('comment')}>
                <FormControl
                  disabled
                  as='textarea'
                  placeholder=''
                  value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam reprehenderit necessitatibus vel amet natus eligendi, praesentium laudantium voluptatibus voluptatum totam nemo dicta distinctio excepturi, itaque eos! Sint itaque pariatur obcaecati.
                      Omnis quasi, consequatur corporis, ratione obcaecati nesciunt est aliquid illum quas error quisquam suscipit iure aliquam nobis eius voluptatem officia minima soluta sed porro impedit illo ea. Deserunt, nihil explicabo.'
                  style={{minHeight: '100px', maxHeight: '300px'}}
                />
              </FloatingLabel>
            </div>
          </div>
          <div className='row pt-8 g-3'>
            <div className='fw-bold fs-5 d-flex justify-content-center'>
              {t('breakdown_picture')}
            </div>
            <div className='separator my-2'></div>
            <img
              className='rounded-3 '
              src='https://www.devsanled.com.tr/wp-content/uploads/2021/06/trafo-nedir.jpg'
              alt=''
            />
          </div>
        </Modal.Body>
        <Modal.Footer className='d-print-none'>
          <div className='d-flex justify-content-center w-100'>
            <div className='text-center'>
              <button
                onClick={handlePrint}
                type='submit'
                id='kt_sign_in_submit'
                className={`btn btn-primary  py-4`}
              >
                <span style={{display: 'block'}}>{t('print')}</span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default BreakdownModal
