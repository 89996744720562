import {ErrorMessage, Field, useFormikContext} from 'formik'
import React from 'react'
import {FloatingLabel, FormControl} from 'react-bootstrap'
import Popover from '../../partials/popover/Popover'
import InputMask from 'react-input-mask'

function InputComponent({
  name,
  type,
  controlId,
  label,
  col,
  disabled,
  hint,
  as,
  mask,
  maskChar,
  errorMessage,
  isAutoComplete,
  upperCase,
  maxLength,
}: any) {
  /*
  name=Datayı içeri koymak için
  type=Text,number gibi tipleri belirlemek için
  col=Kaç column olacağını belirlemek için
  disabled=true değeri gelirse input'u disable yapmak için
  hint=hint değeri gelirse popover içinde info göstermek için
  as=as değeri gelirse 'textarea' gibi değiştirmek için default değer 'input'
  errorMessage=false gelirse 'name' ile mesaj gösterilmeyecek
  isAutoComplete='on' gelirse açık yoksa kapalı
  upperCase=true false değerleri alabilir maske içi büyük harfe dönüştürmek için
  maxLength=sınırlamak için
  */

  // formiki alıp error varsa border renklerini değiştirmek için
  const formik: any = useFormikContext()
  return (
    <>
      <FloatingLabel
        controlId={controlId}
        label={label}
        className={`${disabled ? 'bg-light' : ''} rounded d-flex align-items-center ${
          formik.touched[name] && formik.errors[name] ? 'border-danger' : 'border-secondary'
        } border ${col} fs-6 text-gray-600`}
      >
        {/* MASKE VARSA MASKELİYİ KULLAN YOKSA NORMAL */}
        {mask ? (
          <FormControl
            as={InputMask}
            disabled={disabled === true ? true : false}
            mask={mask} // Maske
            maskChar={maskChar ? maskChar : ''} // Maske boş karakterleri
            type={type}
            formatChars={{
              '9': '[0-9.0-9.0-9]',
              a: '[A-Za-z]',
              '/': '[A-Fa-f0-9]',
              s: '[0-9.]',
            }}
            placeholder=''
            autoComplete={isAutoComplete === 'on' ? 'on' : 'one-time-code'}
            className={`border-0 bg-transparent text-gray-800 fw-bold min-h-55px w-100 ${
              disabled && 'pe-none'
            }`}
            {...formik.getFieldProps(name)}
            onChange={(e) => {
              let value = e.target.value
              // upperCase alanı true gönderilmişse value'yu upperCase yapıp field'ı doldur
              if (upperCase === true) {
                let upperCaseValue = value.toUpperCase()
                formik.setFieldValue(name, upperCaseValue) // Değeri formik alanına ayarlama
              } else {
                formik.setFieldValue(name, value) // Değeri formik alanına ayarlama
              }
              console.log(value)
            }}
          />
        ) : (
          <FormControl
            type={type}
            as={as === 'textarea' ? 'textarea' : 'input'}
            placeholder=''
            maxLength={maxLength ? maxLength : null}
            disabled={disabled === true ? true : false}
            autoComplete={isAutoComplete === 'on' ? 'on' : 'one-time-code'}
            className={`border-0 bg-transparent text-gray-800 fw-bold min-h-55px w-100 ${
              disabled && 'pe-none'
            }`}
            {...formik.getFieldProps(name)}
          />
        )}
        {hint && (
          <div className='me-2'>
            <Popover content={hint} />
          </div>
        )}
      </FloatingLabel>

      {errorMessage !== false && (
        <ErrorMessage
          name={name}
          className='alert-text font-weight-bold '
          render={(msg) => <div className='mt-2 alert alert-danger col-12'>{msg}</div>}
        />
      )}
    </>
  )
}

export default InputComponent
