import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {TableMain} from '../../_metronic/partials/widgets'
import {KTSVG} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'
import SubscriberEditModal from '../../_metronic/partials/modals/SubscriberEditModal'
import ReceiptModal from '../../_metronic/partials/modals/ReceiptModal'

function Subscribers() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const columnItems = [
    {
      subscriber_id: '12AD4FCH21P',
      subscriber_type: 'Mesken',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'John',
    },
    {
      subscriber_id: '89G7SDF6G5H',
      subscriber_type: 'İş yeri',
      tariff_type: 'Çok Zamanlı',
      subscriber_name: 'Jane',
    },
    {
      subscriber_id: '43HJ8DFH6GH',
      subscriber_type: 'Mesken',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Alice',
    },
    {
      subscriber_id: '98KJH6JF8HG',
      subscriber_type: 'Mesken',
      tariff_type: 'Çok Zamanlı',
      subscriber_name: 'Bob',
    },
    {
      subscriber_id: '43H8DFHG43KH',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Emily',
    },
    {
      subscriber_id: '78KHG78JK89L',
      subscriber_type: 'Mesken',
      tariff_type: 'Çok Zamanlı',
      subscriber_name: 'Michael',
    },
    {
      subscriber_id: '32SDS86DK2L',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Emma',
    },
    {
      subscriber_id: '98L9G8JH8G7J',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'David',
    },
    {
      subscriber_id: '78HJ78HJ6J78',
      subscriber_type: 'Mesken',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Sarah',
    },
    {
      subscriber_id: '09KJ8FJG86J5',
      subscriber_type: 'Mesken',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Chris',
    },
    {
      subscriber_id: '87KJH87K6J7L',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Olivia',
    },
    {
      subscriber_id: '89JH6G8J7H87',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Daniel',
    },
    {
      subscriber_id: '43HJ8DHF7G67',
      subscriber_type: 'Mesken',
      tariff_type: 'Çok Zamanlı',
      subscriber_name: 'Sophia',
    },
    {
      subscriber_id: '65KJH87KJ98L',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Matthew',
    },
    {
      subscriber_id: '89LK98JH8G7J',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Isabella',
    },
    {
      subscriber_id: '43HJK7HJG8K9',
      subscriber_type: 'Mesken',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Ethan',
    },
    {
      subscriber_id: '89KJ87KJ9G7H',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Ava',
    },
    {
      subscriber_id: '34KJH98G76K8',
      subscriber_type: 'Mesken',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Noah',
    },
    {
      subscriber_id: '76HJG87K9J87',
      subscriber_type: 'İş yeri',
      tariff_type: 'Tek Zamanlı',
      subscriber_name: 'Mia',
    },
    {
      subscriber_id: '43HJK87G6H89',
      subscriber_type: 'Mesken',
      tariff_type: 'Çok Zamanlı',
      subscriber_name: 'Liam',
    },
  ]

  const column: IColumnHeader[] = [
    {
      Header: t('subscriber_id'),
      accessor: 'subscriber_id',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
    {
      Header: t('subscriber_name'),
      accessor: 'subscriber_name',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
    {
      Header: t('subscriber_type'),
      accessor: 'subscriber_type',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
    {
      Header: t('tariff_type'),
      accessor: 'tariff_type',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
  ]

  const [showSubscriberEditModal, setShowSubscriberEditModal] = useState(false)
  const [showReceiptModal, setShowReceiptModal] = useState(false)

  const button = (
    <>
      <div
        onClick={(e) => setShowSubscriberEditModal(true)}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb'
      >
        <KTSVG path='/media/icons/bootstrap/aside/person-fill.svg' className='svg-icon-4' />
      </div>
      <Link
        to='/counter-detail'
        className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1 mb'
      >
        <KTSVG path='/media/icons/bootstrap/buttons/lightning-fill.svg' className='svg-icon-4' />
      </Link>
      <div
        onClick={(e) => setShowReceiptModal(true)}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb'
      >
        <KTSVG path='/media/icons/bootstrap/buttons/receipt.svg' className='svg-icon-4' />
      </div>
    </>
  )

  return (
    <>
      <PageTitle>{t('subscribers')}</PageTitle>
      <SubscriberEditModal
        showModal={showSubscriberEditModal}
        setShowModal={setShowSubscriberEditModal}
      />
      <ReceiptModal showModal={showReceiptModal} setShowModal={setShowReceiptModal} />
      <TableMain
        column={column}
        columnItems={columnItems}
        button={button}
        className='mb-5 mb-xl-8'
      />
    </>
  )
}

export default Subscribers
