import React, {useState} from 'react'
import {FloatingLabel, FormControl, FormSelect} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {generateData} from '../consts/const'
import ImageHandler from '../../_metronic/layout/components/ImageHandler'
import SubmitButton from '../../_metronic/layout/components/SubmitButton'

const {transformersData, techData} = generateData()

function BreakdownCreate() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [selectedTech, setSelectedTech] = useState<any>({
    name: techData[0].name,
    phone: techData[0].phone,
  })

  const [selectedTransformer, setSelectedTransformer] = useState({
    id: transformersData[0].id,
    location: transformersData[0].locations,
    fullLocation: transformersData[0].fullLocation,
  })

  const handleTransformerChange = (e: any) => {
    const selectedId = e.target.value
    const selectedTransformer = transformersData.find(
      (transformer: any) => transformer.id === selectedId
    )
    setSelectedTransformer(selectedTransformer)
  }

  const handleTechChange = (e: any) => {
    const selectedName = e.target.value
    const selectedTech = techData.find((tech: any) => tech.name === selectedName)
    setSelectedTech({
      name: selectedName,
      phone: selectedTech.phone,
    })
  }

  return (
    <>
      <PageTitle>{t('breakdown_create')}</PageTitle>
      <div className='card'>
        <div className='card-body pb-0'>
          <div className='row g-3'>
            <div className='col-6'>
              <FloatingLabel
                controlId='floatingInput'
                label={t('transformer_id')}
                className='text-gray-700'
              >
                <FormSelect
                  style={{height: '55px'}}
                  placeholder=''
                  onChange={handleTransformerChange}
                  value={selectedTransformer.id}
                >
                  {transformersData.map((item: any) => (
                    <option value={item.id}>
                      {item.id} - {item.location}
                    </option>
                  ))}
                </FormSelect>
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel
                controlId='floatingInput'
                label={t('location')}
                className='text-gray-700'
              >
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value={selectedTransformer.fullLocation}
                />
              </FloatingLabel>
            </div>

            <div className='col-6'>
              <FloatingLabel
                controlId='floatingInput'
                label={t('breakdown_type')}
                className='text-gray-700'
              >
                <FormControl style={{height: '55px'}} placeholder='' />
              </FloatingLabel>
            </div>
          </div>
          <div className='row g-3 pt-4'>
            <div className='col-6'>
              <FloatingLabel
                controlId='floatingInput'
                label={t('technician_name')}
                className='text-gray-700'
              >
                <FormSelect
                  className='hoverable'
                  style={{height: '55px'}}
                  placeholder=''
                  onChange={handleTechChange}
                  value={selectedTech.name}
                >
                  {techData.map((item: any) => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </FormSelect>
              </FloatingLabel>
            </div>{' '}
            <div className='col-6'>
              <FloatingLabel
                controlId='floatingInput'
                label={t('technician_phone')}
                className='text-gray-700'
              >
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value={selectedTech.phone}
                />
              </FloatingLabel>
            </div>
            <div className='col-12'>
              <FloatingLabel
                className='text-gray-700'
                controlId='floatingTextarea2'
                label={t('comment')}
              >
                <FormControl as='textarea' placeholder='' style={{height: '100px'}} />
              </FloatingLabel>
            </div>
          </div>
          <div className='row pt-8 g-3'>
            <div className='fw-bold fs-5 d-flex justify-content-center'>
              {t('breakdown_picture')}
            </div>
            <div className='separator my-2'></div>
            <ImageHandler />
          </div>
        </div>
        {/* Submit form */}
        <div className='mb-4'>
          <SubmitButton
            text={t('save')}
            width={'w-200px'}
            successAlert={t('breakdown_create_success')}
          />
        </div>
      </div>
    </>
  )
}

export default BreakdownCreate
