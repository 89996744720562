/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import CountUp from 'react-countup'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  description: string
  url: string
  number?: number
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  description,
  number,
  url,
}) => {
  return (
    <Link to={url} className={`card bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        <div className='d-flex align-items-center justify-content-between'>
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
          <div className={`text-black fw-bolder fs-1 mb-2 mt-5 bg-white rounded p-2`}>
            <CountUp duration={2} end={number || 0} />
          </div>
        </div>

        <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>{title}</div>

        <div className='d-flex align-items-center'>
          <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
          <KTSVG
            path='/media/icons/duotune/arrows/arr064.svg'
            className={`svg-icon-${iconColor} svg-icon-2x ms-n1 ps-2`}
          />
        </div>
      </div>
      {/* end::Body */}
    </Link>
  )
}

export {StatisticsWidget5}
