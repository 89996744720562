/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {KTSVG} from '../../../helpers'
import {useIntl} from 'react-intl'
import {categories} from '../../../../app/consts/const'

type Props = {
  className: string
}

const ChartsWidget3: React.FC<Props> = ({className}) => {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  const data1 = [370, 310, 290, 360, 280, 370, 340]
  const data2 = [240, 240, 350, 280, 260, 330, 340]
  const data3 = [180, 220, 240, 250, 340, 350, 340]
  const data4 = [450, 350, 340, 290, 270, 240, 190]
  const data5 = [350, 340, 380, 320, 280, 290, 350]
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    let height: any

    if (chartRef.current) {
      height = parseInt(getCSS(chartRef.current, 'height') || '0')
    }
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data1, data2, data3, data4, data5, categories)
    )

    if (chart) {
      chart.render()
    }
    return () => {
      chart.destroy()
    }
  }, [chartRef])
  useEffect(() => {
    const intervalId = setInterval(() => {
      const a = data1[data1.length - 1] + Math.floor(Math.random() * 41) - 20
      data1.push(a)
      const b = data2[data2.length - 1] + Math.floor(Math.random() * 41) - 20
      data2.push(b)
      const c = data3[data3.length - 1] + Math.floor(Math.random() * 41) - 20
      data3.push(c)
      const d = data4[data4.length - 1] + Math.floor(Math.random() * 41) - 20
      data4.push(d)
      const e = data5[data5.length - 1] + Math.floor(Math.random() * 41) - 20
      data5.push(e)
      ApexCharts.exec('chart1', 'updateSeries', [
        {
          name: 'Trafo 1',
          data: data1,
        },
        {
          name: 'Trafo 2',
          data: data2,
        },
        {
          name: 'Trafo 3',
          data: data3,
        },
        {
          name: 'Trafo 4',
          data: data4,
        },
        {
          name: 'Trafo 5',
          data: data5,
        },
      ])
      function generateHourCategories() {
        const hour = categories[categories.length - 1]
        const hourParts = Number(parseInt(hour.split(':')[0]))
        const hourNumber = hourParts + 1
        let stringNumber = String(hourNumber) + ':00'
        if (hourNumber === 24) {
          stringNumber = '00:00'
        }
        if (stringNumber.length === 4) {
          categories.push('0' + stringNumber)
        } else {
          categories.push(stringNumber)
        }
      }
      generateHourCategories()
    }, 3000)

    return () => clearInterval(intervalId)
  }, [])

  const handleclick = () => {
    console.log(categories)
  }

  return (
    <div className={`card ${className} px-0 mx-0`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{t('transformer_usage')}</span>

          {/* <span className='text-muted fw-bold fs-7'>More than 1000 new records</span> */}
        </h3>

        {/* begin::Toolbar */}
        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_week_btn'
          >
            Week
          </a>
        </div> */}
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body px-0 mx-0'>
        {/* begin::Chart */}
        <div>
          <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        </div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget3}

function getChartOptions(
  height: number,
  data1: any,
  data2: any,
  data3: any,
  data4: any,
  data5: any,
  categories: any
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const data1Color = getCSSVariableValue('--bs-info')
  const data2Color = getCSSVariableValue('--bs-warning')
  const data3Color = getCSSVariableValue('--bs-success')
  const data4Color = getCSSVariableValue('--bs-danger')
  const data5Color = getCSSVariableValue('--bs-primary')
  const lightColor = 'rgba(0, 0, 0, 0)'

  return {
    series: [
      {
        name: 'Trafo 1',
        data: data1,
      },
      {
        name: 'Trafo 2',
        data: data2,
      },
      {
        name: 'Trafo 3',
        data: data3,
      },
      {
        name: 'Trafo 4',
        data: data4,
      },
      {
        name: 'Trafo 5',
        data: data5,
      },
    ],
    chart: {
      id: 'chart1',
      fontFamily: 'inherit',
      type: 'line',
      height: 350,
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: {
        show: false,
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
        autoSelected: 'pan',
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [data1Color, data2Color, data3Color, data4Color, data5Color],
    },
    xaxis: {
      range: 6,
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: data1Color,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' kW'
        },
      },
    },
    colors: [data1Color, data2Color, data3Color, data4Color, data5Color],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 1,
      strokeColors: [data1Color, data2Color, data3Color, data4Color, data5Color],
      strokeWidth: 3,
    },
  }
}
