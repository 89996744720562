import {Tooltip} from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {KTSVG} from '../../helpers'

function Popover({content}: any) {
  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id='button-tooltip-2'>
          <span className='text-white' style={{whiteSpace: 'pre-line'}}>
            {content}
          </span>
        </Tooltip>
      }
    >
      {({ref, ...triggerHandler}) => (
        <div className='d-inline-flex align-items-center' ref={ref} {...triggerHandler}>
          <KTSVG
            path='/media/icons/duotune/general/gen045.svg'
            className='text-gray-500 svg-icon-2x'
          />
        </div>
      )}
    </OverlayTrigger>
  )
}

export default Popover
