/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useIntl} from 'react-intl'

type Props = {
  className: string
  totalData?: any
}

const ChartsWidget1: React.FC<Props> = ({className, totalData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [selectedDate, setSelectedDate] = useState('year')
  const [thisTotal, setThisTotal] = useState(0)
  const [lastTotal, setLastTotal] = useState(0)
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const cats = [...totalData[selectedDate + 'Data'].map((item: any) => item.name)]
    const thisData = [...totalData[selectedDate + 'Data'].map((item: any) => item.this)]
    const lastData = [...totalData[selectedDate + 'Data'].map((item: any) => item.last)]

    // this
    const total = totalData[selectedDate + 'Data'].reduce((acc: any, item: any) => {
      return acc + item.this
    }, 0)
    // last
    const total2 = totalData[selectedDate + 'Data'].reduce((acc: any, item: any) => {
      return acc + item.last
    }, 0)
    setThisTotal(total)
    setLastTotal(total2)
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, cats, thisData, lastData)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, totalData, selectedDate])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{t('cities')}</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1
            ${selectedDate === 'year' && 'active'}
            `}
            id='kt_charts_widget_2_year_btn'
            onClick={(e) => setSelectedDate('year')}
          >
            {t('yearly')}
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1
            ${selectedDate === 'month' && 'active'}
            `}
            id='kt_charts_widget_2_month_btn'
            onClick={(e) => setSelectedDate('month')}
          >
            {t('monthly')}
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${
              selectedDate === 'week' && 'active'
            }`}
            id='kt_charts_widget_2_week_btn'
            onClick={(e) => setSelectedDate('week')}
          >
            {t('weekly')}
          </a>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '320px'}} />
        {/* end::Chart */}
        <div className='d-flex row g-2 card-label fw-bold'>
          <div className='d-flex'>
            <span className='bg-warning d-flex h-12px w-20px me-2 rounded-1'></span>
            {t(`this_${[selectedDate]}`)} -{' '}
            <span className='text-muted ms-2'>
              {t('total')} {thisTotal} mW
            </span>
          </div>
          <div className='d-flex'>
            <span className='bg-gray-300 d-flex h-12px w-20px me-2 rounded-1'></span>
            {t(`last_${[selectedDate]}`)} -{' '}
            <span className='text-muted ms-2'>
              {t('total')} {lastTotal} mW
            </span>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget1}

function getChartOptions(height: number, cats: any, thisData: any, lastData: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-warning')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  return {
    series: [
      {
        name: 'This Year',
        data: thisData,
      },
      {
        name: 'Last Year',
        data: lastData,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: cats,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' mW'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
