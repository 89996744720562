import React from 'react'
import {FloatingLabel, FormControl, FormSelect, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import SubmitButton from '../../layout/components/SubmitButton'

function SubscriberEditModal({showModal, setShowModal}: any) {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)
  const handleCloseModal = () => {
    setShowModal(false)
  }
  return (
    <Modal
      style={{background: ' rgba(0,0,0,0.3)'}}
      size='lg'
      show={showModal}
      onHide={handleCloseModal}
      centered
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{t('subscriber_info')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row g-3'>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('subscriber_id')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='1AD231FGGH12'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('subscriber_name')} className=''>
                <FormControl
                  disabled
                  style={{height: '55px'}}
                  placeholder=''
                  value='Tony Soprano'
                />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('subscriber_type')} className=''>
                <FormControl disabled style={{height: '55px'}} placeholder='' value='İş yeri' />
              </FloatingLabel>
            </div>
            <div className='col-6'>
              <FloatingLabel controlId='floatingInput' label={t('tariff_type')} className=''>
                <FormSelect style={{height: '55px'}} className='hoverable w-100'>
                  <option>{t('single_time')}</option>
                  <option>{t('multi_time')}</option>
                </FormSelect>
              </FloatingLabel>
            </div>
          </div>
        </Modal.Body>
        {/* Submit form */}
        <SubmitButton text={t('save')} width={'w-200px'} successAlert={t('settings_updated')} />
      </div>
    </Modal>
  )
}

export default SubscriberEditModal
