import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {ChartsWidget3} from '../../_metronic/partials/widgets'
import {FloatingLabel, FormSelect, Offcanvas} from 'react-bootstrap'
import {GaugeChartTest} from '../../_metronic/partials/widgets/charts/GaugeChartTest'
import MapTest from '../../_metronic/layout/components/MapTest'

function Monitoring() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const initialData = {
    trafo1: {
      color: 'success',
      val: 93,
    },
    trafo2: {
      color: 'danger',
      val: 34,
    },
    trafo3: {
      color: 'danger',
      val: 44,
    },
    trafo4: {
      color: 'success',
      val: 93,
    },
    trafo5: {
      color: 'success',
      val: 92,
    },
  }

  const [data, setData] = useState(initialData)

  const getRandomColor = () => (Math.random() < 0.5 ? 'success' : 'danger')
  useEffect(() => {
    const intervalId = setInterval(() => {
      setData((prevData) => {
        const newData: any = {...prevData}
        Object.keys(newData).forEach((key) => {
          newData[key].color = getRandomColor()
        })
        return newData
      })
    }, 3000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <PageTitle>{t('monitoring')}</PageTitle>
      <div className='card'>
        <div className='row card-body p-5 g-4'>
          <div className='col-6 col-md-3'>
            <FloatingLabel controlId='floatingSelect' label={t('region')}>
              <FormSelect className='hoverable'>
                <option value='all'>{t('all')}</option>
              </FormSelect>
            </FloatingLabel>
          </div>
          <div className='col-6 col-md-3'>
            <FloatingLabel controlId='floatingSelect' label={t('city')}>
              <FormSelect className='hoverable'>
                <option value='all'>{t('all')}</option>
              </FormSelect>
            </FloatingLabel>
          </div>
          <div className='col-6 col-md-3'>
            <FloatingLabel controlId='floatingSelect' label={t('district')}>
              <FormSelect className='hoverable'>
                <option value='all'>{t('all')}</option>
              </FormSelect>
            </FloatingLabel>
          </div>
        </div>
      </div>
      <div className='card mt-4'>
        <div className='card-body w-lg-75 mx-auto'>
          <MapTest />
        </div>
      </div>
      <div className='card mt-4'>
        <div className='row card-body px-0 px-lg-4'>
          <div className={`col-xl-8`}>
            <ChartsWidget3 className='' />
          </div>
          <div className={` col-xl-4 `}>
            <div className='card-body'>
              <div className='card-label fw-bolder fs-3'>{t('transformer_states')}</div>
              <div className='fw-bold fs-5 p-4'>
                <div
                  className={`row d-flex border-bottom align-items-center py-2 text-${data.trafo1.color}`}
                >
                  <div className='col-6 '>{t('transformer')} 1</div>
                  <div className='col-6'>
                    <div
                      className={`ms-auto p-2 w-10px rounded-circle bg-${data.trafo1.color}`}
                    ></div>
                  </div>
                </div>
                <div
                  className={`row d-flex border-bottom align-items-center py-2 text-${data.trafo2.color}`}
                >
                  <div className='col-6 '>{t('transformer')} 2</div>
                  <div className='col-6'>
                    <div
                      className={`ms-auto p-2 w-10px rounded-circle bg-${data.trafo2.color}`}
                    ></div>
                  </div>
                </div>
                <div
                  className={`row d-flex border-bottom align-items-center py-2 text-${data.trafo3.color}`}
                >
                  <div className='col-6 '>{t('transformer')} 3</div>
                  <div className='col-6'>
                    <div
                      className={`ms-auto p-2 w-10px rounded-circle bg-${data.trafo3.color}`}
                    ></div>
                  </div>
                </div>
                <div
                  className={`row d-flex border-bottom align-items-center py-2 text-${data.trafo4.color}`}
                >
                  <div className='col-6 '>{t('transformer')} 4</div>
                  <div className='col-6'>
                    <div
                      className={`ms-auto p-2 w-10px rounded-circle bg-${data.trafo4.color}`}
                    ></div>
                  </div>
                </div>
                <div
                  className={`row d-flex border-bottom align-items-center py-2 text-${data.trafo5.color}`}
                >
                  <div className='col-6 '>{t('transformer')} 5</div>
                  <div className='col-6'>
                    <div
                      className={`ms-auto p-2 w-10px rounded-circle bg-${data.trafo5.color}`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Monitoring
