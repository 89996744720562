import React, {FC, createContext, useContext} from 'react'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'tr' | 'en'
}
type Lang = 'tr' | 'en'
var defaultLang: Lang = 'en'
if (navigator.language === 'tr-TR' || navigator.language === 'tr' || navigator.language === 'TR') {
  defaultLang = 'tr'
}
const initialState: Props = {
  selectedLang: defaultLang,
}
const selectedLanguage = localStorage.getItem('i18nConfig')
if (!selectedLanguage) {
  try {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: defaultLang}))
  } catch {}
}
function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
