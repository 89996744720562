/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'

interface ISubItems {
  to: string
  icon: string
  title: string
  expired?: boolean
}

interface IMenuItems {
  to: string
  title: string
  icon: string
  expired?: boolean
  subItems?: ISubItems[]
}

export function AsideMenuMain() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [isExpired, setIsExpired] = useState(false)

  console.log(isExpired)
  const menuItems: IMenuItems[] = [
    // Home
    {
      to: '/home',
      title: t('dashboard'),
      icon: '/media/icons/bootstrap/aside/pie-chart-fill.svg',
    },
    {
      to: '/breakdowns',
      title: t('breakdowns'),
      icon: '/media/icons/bootstrap/aside/exclamation-diamond-fill.svg',
    },
    {
      to: '/monitoring',
      title: t('monitoring'),
      icon: '/media/icons/bootstrap/aside/display.svg',
    },
    {
      to: '#',
      title: t('subscriber_settings'),
      icon: '/media/icons/bootstrap/aside/people-fill.svg',
      subItems: [
        {
          to: '/subscribers',
          title: t('subscribers'),
          icon: '/media/icons/bootstrap/aside/people-fill.svg',
        },
        {
          to: '/counter',
          title: t('counters'),
          icon: '/media/icons/bootstrap/buttons/lightning-fill.svg',
        },
      ],
    },

    {
      to: '/settings',
      title: t('settings'),
      icon: '/media/icons/bootstrap/aside/gear-fill.svg',
    },
  ]

  return (
    <>
      <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 ms-8 mb-6'>
        <div className='d-lg-none d-flex align-items-center'>
          <img alt='Logo' src={toAbsoluteUrl('/netway.png')} className='h-30px' />
          <h2 className='text-white mt-2 ms-4'>Wisnet</h2>
        </div>
      </div>
      {menuItems.map((item, index) =>
        item.subItems ? (
          <AsideMenuItemWithSub
            key={index}
            to={item.to}
            disabled={item.expired}
            title={item.title}
            fontIcon='bi-archive'
            icon={item.icon}
          >
            {item.subItems.map((item, index) => (
              <AsideMenuItem
                key={index}
                to={item.to}
                disabled={item.expired}
                icon={item.icon}
                title={item.title}
                iconSize='6'
              />
            ))}
          </AsideMenuItemWithSub>
        ) : (
          <AsideMenuItem
            key={index}
            to={item.to}
            disabled={item.expired}
            icon={item.icon}
            title={item.title}
            fontIcon='bi-layers'
          />
        )
      )}
    </>
  )
}
