import React from 'react'
import {KTSVG} from '../../../helpers'

function TableSearch({filter, setFilter}: any) {
  return (
    <form className='border border-1 d-flex align-items-center px-3 justify-content-between rounded'>
      <input
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        className='border-0 py-2 w-100'
        style={{outline: 'none'}}
      />
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500'
      />
    </form>
  )
}

export default TableSearch
