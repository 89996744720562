import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {TableMain} from '../../_metronic/partials/widgets'
import {KTSVG} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'
import BreakdownModal from '../../_metronic/partials/modals/BreakdownModal'
import {FloatingLabel, FormControl, FormSelect} from 'react-bootstrap'

function Breakdowns() {
  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [data, setData] = useState([
    {
      trafNo: '92DBL221E',
      location: '39.5455, 39.5455',
      type: 'Arıza tipi 1',
      city: 'Bakü',
      status: 'Arızalı',
    },
    {
      trafNo: '99AOL224S',
      location: '34.2312, 34.2442',
      type: 'Arıza tipi 1',
      city: 'Bakü',
      status: 'Çözüldü',
    },
    {
      trafNo: '22KKL421F',
      location: '42.3212, 32.1231',
      type: 'Arıza tipi 3',
      city: 'Bakü',
      status: 'Arızalı',
    },
    {
      trafNo: '33SBS242M',
      location: '52.3221, 42.1232',
      type: 'Arıza tipi 3',
      city: 'Bakü',
      status: 'Çözüldü',
    },
  ])

  const columnItems = data

  const column: IColumnHeader[] = [
    {
      Header: 'Trafo Numarası',
      accessor: 'trafNo',
      sortType: 'alphanumeric',
      classname: 'min-w-200px',
    },
    {
      Header: t('city'),
      accessor: 'city',
      sortType: 'alphanumeric',
      classname: 'min-w-200px',
    },
    {
      Header: t('location'),
      accessor: 'location',
      sortType: 'alphanumeric',
      classname: 'min-w-200px',
    },
    {
      Header: 'Arıza Tipi',
      accessor: 'type',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
    },
    {
      Header: 'Durum',
      accessor: 'status',
      sortType: 'alphanumeric',
      classname: 'min-w-150px',
      Cell: (s: any) => (
        <div className={`text-${s.value === 'Çözüldü' ? 'success' : 'danger'}`}>{s.value}</div>
      ),
    },
  ]

  const [showModal, setShowModal] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [contentStatus, setContentStatus] = useState('all')

  const handleContentStatus = (e: any) => {
    setContentStatus(e.target.value)
    if (e.target.value === 'all') {
      setData([
        {
          trafNo: '92DBL221E',
          location: '39.5455, 39.5455',
          type: 'Arıza tipi 1',
          city: 'Bakü',
          status: 'Arızalı',
        },
        {
          trafNo: '99AOL224S',
          location: '34.2312, 34.2442',
          type: 'Arıza tipi 1',
          city: 'Bakü',
          status: 'Çözüldü',
        },
        {
          trafNo: '22KKL421F',
          location: '42.3212, 32.1231',
          type: 'Arıza tipi 3',
          city: 'Bakü',
          status: 'Arızalı',
        },
        {
          trafNo: '33SBS242M',
          location: '52.3221, 42.1232',
          type: 'Arıza tipi 3',
          city: 'Bakü',
          status: 'Çözüldü',
        },
      ])
    } else if (e.target.value === 'active') {
      setData([
        {
          trafNo: '92DBL221E',
          location: '39.5455, 39.5455',
          type: 'Arıza tipi 1',
          city: 'Bakü',
          status: 'Arızalı',
        },
        {
          trafNo: '22KKL421F',
          location: '42.3212, 32.1231',
          type: 'Arıza tipi 3',
          city: 'Bakü',
          status: 'Arızalı',
        },
      ])
    } else {
      setData([
        {
          trafNo: '99AOL224S',
          location: '34.2312, 34.2442',
          type: 'Arıza tipi 1',
          city: 'Bakü',
          status: 'Çözüldü',
        },
        {
          trafNo: '33SBS242M',
          location: '52.3221, 42.1232',
          type: 'Arıza tipi 3',
          city: 'Bakü',
          status: 'Çözüldü',
        },
      ])
    }
  }

  const button = (
    <>
      <div
        onClick={(e) => setShowModal(true)}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 mb'
      >
        <KTSVG path='/media/icons/bootstrap/buttons/list.svg' className='svg-icon-4' />
      </div>
    </>
  )

  const header = (
    <div className='row w-100 g-3'>
      <div className='col-12 col-xl-6 d-flex'>
        <Link
          to={'/breakdowns/create'}
          className='bg-info h-100  d-flex align-items-center hoverable p-3 fs-6 text-white fw-bold rounded border me-2'
        >
          {t('breakdown_create')}
        </Link>
      </div>
      <div className='col-12 col-xl-6'>
        <div className='row d-flex justify-content-xl-end g-3'>
          <div className='col-6 col-xl-4'>
            <FloatingLabel controlId='floatingInput' label={t('start_date')}>
              <FormControl
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                type='date'
                placeholder=''
              />
            </FloatingLabel>
          </div>
          <div className='col-6 col-xl-4 '>
            <FloatingLabel controlId='floatingInput' label={t('end_date')}>
              <FormControl
                onChange={(e) => setEndDate(e.target.value)}
                type='date'
                value={endDate}
                placeholder=''
              />
            </FloatingLabel>
          </div>
          <div className='col-6 col-xl-4'>
            <FloatingLabel
              controlId='floatingInput'
              label={t('transformer_id')}
              className='text-gray-700'
            >
              <FormSelect
                style={{height: '55px'}}
                placeholder=''
                onChange={handleContentStatus}
                value={contentStatus}
              >
                <option value='all'>Tümü</option>
                <option value='active'>Aktif Arızalar</option>
                <option value='passive'>Çözülen Arızalar</option>
              </FormSelect>
            </FloatingLabel>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <PageTitle>{t('breakdowns')}</PageTitle>
      <BreakdownModal showModal={showModal} setShowModal={setShowModal} />
      <TableMain
        column={column}
        columnItems={columnItems}
        header={header}
        button={button}
        className='mb-5 mb-xl-8'
      />
    </>
  )
}

export default Breakdowns
