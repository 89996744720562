// İlgili fonksiyonları içeren dosya (örneğin: dataGenerator.ts)

import {useIntl} from 'react-intl'

function generateRandomId(): string {
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomId: string = ''

  for (let i = 0; i < 10; i++) {
    const randomIndex: number = Math.floor(Math.random() * characters.length)
    randomId += characters.charAt(randomIndex)
  }

  return randomId
}
export const categories = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00']

export function generateData(): {transformersData: any[]; techData: any[]} {
  const locations: string[] = [
    'İstanbul',
    'Bakü',
    'Mersin',
    'İzmir',
    'İstanbul',
    'Adana',
    'Ankara',
    'Antalya',
    'İzmit',
    'Muğla',
  ]

  const transformersData: any[] = []
  for (let i = 0; i < 10; i++) {
    const randomId: string = generateRandomId()
    const randomLocation: string = locations[Math.floor(Math.random() * locations.length)]
    transformersData.push({
      id: randomId,
      location: randomLocation,
      fullLocation: `${randomLocation} / District ${i + 1}`,
    })
  }

  const techData: any[] = []
  for (let i = 0; i < 10; i++) {
    const randomName: string = 'Person' + (i + 1)
    const randomNumber: string =
      '05' +
      Math.floor(Math.random() * 1000000000)
        .toString()
        .padStart(9, '0')
    techData.push({
      name: randomName,
      phone: randomNumber,
    })
  }

  return {transformersData, techData}
}
