import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {ErrorMessage, Form, Formik, useFormik, useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import InputComponent from '../../../../_metronic/layout/components/InputComponent'
import axios from 'axios'
import {toast} from 'react-toastify'

// Panel dışı işlem için

export function ForgotPasswordConfirm() {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const [mail, setMail] = useState('')

  useEffect(() => {
    let sessionMail = sessionStorage.getItem('mail')

    sessionMail && setMail(sessionMail)
  }, [])

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('email_required')).email(t('email_valid_alert')),
    verify_code: Yup.string().trim().required(t('required_field_alert')),
    password: Yup.string()
      .required(t('required_field_alert'))
      .min(8, t('password_min_alert'))
      .matches(/[a-z]/, t('password_lower_alert'))
      .matches(/[A-Z]/, t('password_upper_alert'))
      .matches(/\d/, t('password_number_alert'))
      .matches(/[@$!%*?&]/, t('password_special_alert')),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('password_mismatch') // Şifrelerin eşleşmediği durumda gösterilecek hata mesajı
    ),
  })

  forgotPasswordSchema.validate({password: ''}, {abortEarly: false}).catch(console.log)

  const initialValues = {
    email: mail || '',
    verify_code: '',
    password: '',
    password_confirmation: '',
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: any = {}
          return forgotPasswordSchema
            .validate(values, {abortEarly: false})
            .then(() => {})
            .catch((err) => {
              // Tüm hataları al
              err.inner.forEach((e: any) => {
                if (!(e.path in errors)) errors[e.path] = []
                errors[e.path] = errors[e.path].concat(e.errors)
              })
              // Sadece 'password' alanı için doğrulama yap
              return forgotPasswordSchema
                .validateAt('password', values, {abortEarly: true})
                .then(() => {
                  // Doğrulama hatası yoksa, sadece 'password' alanındaki hataları döndür
                  return errors.password ? {password: errors.password} : errors // Tüm hataları döndür
                })
                .catch(() => errors) // Hata yakalanırsa, tüm hataları döndür
            })
        }}
        validationSchema={forgotPasswordSchema}
        enableReinitialize
        onSubmit={async (values: any) => {
          const postForm = async () => {
            setLoading(true)
            try {
              const url = 'https://bayi.wisnet.com.tr/api/netway-auth/reset-password'
              const response = await axios.post(url, values)
              console.log(response)
              setLoading(false)
              if (response.data.is_success === true) {
                toast.success(t('reset_success'))
                navigate('/auth/login')
              }
            } catch (error) {
              console.error('Error', error)
              setLoading(false)
            }
          }
          postForm()
        }}
      >
        {({errors, touched, values, handleChange}) => (
          <Form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
          >
            <div className='text-center mb-10'>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>{t('reset_password')}</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-400 fw-bold fs-4'>{t('reset_info')}</div>
              {/* end::Link */}
            </div>
            <div className='row mb-5'>
              <InputComponent
                label={t('email')}
                col='col-12'
                controlId='email'
                type='email'
                name='email'
                errorMessage={false}
              />
              <ErrorMessage
                name='email'
                className='alert-text font-weight-bold '
                render={(msg) => (
                  <div className='mt-2 alert alert-danger col-12'>
                    {console.log(errors.email)}
                    <div className='mb-0'>
                      {errors.email &&
                        (errors.email as any).map((item: any, index: number) => (
                          <div key={index}> {item}</div>
                        ))}
                    </div>
                  </div>
                )}
              />
            </div>

            <div className='row mb-5'>
              <InputComponent
                label={t('verify_code')}
                col='col-12'
                controlId='verifyCode'
                type='text'
                name='verify_code'
              />
            </div>
            <div className='row mb-5'>
              <InputComponent
                label={t('password')}
                col='col-12'
                controlId='password'
                type='password'
                name='password'
                errorMessage={false}
              />
              {console.log(touched)}
              <ErrorMessage
                name='password'
                className='alert-text font-weight-bold '
                render={(msg) => (
                  <div className='mt-2 alert alert-danger col-12'>
                    <ul className='mb-0'>
                      {errors.password &&
                        (errors.password as any).map((item: any, index: number) => (
                          <li key={index}> {item}</li>
                        ))}
                    </ul>
                  </div>
                )}
              />
            </div>
            <div className='row mb-10'>
              <InputComponent
                label={t('password_confirmation')}
                col='col-12'
                controlId='password_confirmation'
                type='password'
                name='password_confirmation'
                errorMessage={false}
              />
              <ErrorMessage
                name='password_confirmation'
                className='alert-text font-weight-bold '
                render={(msg) => (
                  <div className='mt-2 alert alert-danger col-12'>
                    {console.log(errors.password_confirmation)}
                    <div className='mb-0'>
                      {errors.password_confirmation &&
                        (errors.password_confirmation as any).map((item: any, index: number) => (
                          <div key={index}> {item}</div>
                        ))}
                    </div>
                  </div>
                )}
              />
            </div>

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-5'>
              <div className='text-center me-4'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className={`btn btn-primary`}
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>{t('send_link')}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {t('please_wait')}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                >
                  {t('cancel')}
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </Form>
        )}
      </Formik>
    </>
  )
}
