import React, {useState} from 'react'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {ErrorMessage, Form, Formik, useFormik, useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import InputComponent from '../../../../_metronic/layout/components/InputComponent'
import axios from 'axios'
import {toast} from 'react-toastify'

// Panel dışı işlem için

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  // Dil
  const intl = useIntl()
  const t = (id: any, values = {}) => intl.formatMessage({id}, values)

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('email_required')).email(t('email_valid_alert')),
  })

  const initialValues = {
    email: '',
  }

  const handleClick = () => {
    setLoading(true)

    // Zaman aşımını 2 saniye olarak ayarladık, isteğe bağlı olarak süreyi değiştirebilirsiniz
    setTimeout(() => {
      setLoading(false)
      toast.success(t('password_reset_link_sent'))
    }, 1000)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        enableReinitialize
        onSubmit={async (values: any) => {}}
      >
        {({errors, touched, values, handleChange}) => (
          <Form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
          >
            <div className='text-center mb-10'>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>{t('did_you_forgot_password')}</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-400 fw-bold fs-4'>{t('enter_email_to_reset')}</div>
              {/* end::Link */}
            </div>
            <div className='row mb-5'>
              <InputComponent
                label={t('email')}
                col='col-12'
                controlId='email'
                type='email'
                name='email'
                errorMessage={false}
              />
              <ErrorMessage
                name='email'
                className='alert-text font-weight-bold '
                render={(msg) => (
                  <div className='mt-2 alert alert-danger col-12'>
                    <div className='mb-0'>{msg}</div>
                  </div>
                )}
              />
            </div>
            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-5'>
              <div className='text-center me-4'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className={`btn btn-primary`}
                  onClick={handleClick}
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>{t('send_link')}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {t('please_wait')}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                >
                  {t('cancel')}
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </Form>
        )}
      </Formik>
    </>
  )
}
