import React, {useState} from 'react'
import GoogleMapReact from 'google-map-react'
import {KTSVG, toAbsoluteUrl} from '../../helpers'
import TurkeyMap from 'turkey-map-react'

export default function MapTest() {
  const [hoveredCity, setHoveredCity] = useState('Türkiye: 3321')
  return (
    // Important! Always set the container height explicitly
    <div className='h-100' onMouseLeave={() => setHoveredCity('Türkiye: 3321')}>
      <div className='w-100 text-center fw-bold fs-4'>{hoveredCity}</div>
      <TurkeyMap
        hoverable={true}
        onHover={({plateNumber, name}) => setHoveredCity(`${name}: ${plateNumber}`)}
        customStyle={{idleColor: '#447578', hoverColor: '#31595c'}}
      />
    </div>
  )
}
